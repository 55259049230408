import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import "../styles/settings.scss"
import "../styles/style.scss"

const PrivacyPolicyPage = () => (
  <>
    <SEO title="Terms &amp; Conditions" />
    <div className="site-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="about-wrapper">
              <div class="about-content">
                <h2>Terms &amp; Conditions</h2>
                <br />

                <p>
                  <b>Last updated:</b> 16 September 2019
                </p>
                <br />

                <h3>Thank you for choosing Virtus</h3>
                <br />
                <p>
                  Thanks for choosing the Virtus App! Virtus App service
                  ("Service") is provided by Virtus. By using our Service, you
                  are agreeing to these Terms of Service (“Terms”) and certify
                  that you are over the age of 18.
                </p>
                <br />

                <h3>Change of Terms of Service</h3>
                <br />
                <p>
                  Existing Virtus Terms of Service may be modified and/or
                  updated from time to time with additional terms that apply to
                  the Service. For example, Virtus may modify existing terms to
                  reflect any mandatory changes to the law. You should check the
                  terms regulary to keep updated. Changes addressing new
                  functions for the service or changes made for legal reasons my
                  be effective immediately. You should discontinue your use of
                  our Service if you do not agree with the updated/modified
                  Terms.
                </p>
                <br />

                <h3>Your Privacy Protection</h3>
                <br />
                <p>
                  Virtus's <Link to="/privacy">Privacy Policy</Link> explain how
                  we treat your personal data and protect your privacy when
                  using our Service. By using our Service, you automatically
                  agree to our privacy policies and Virtus can use such data in
                  accordance with its privacy policies.
                </p>
                <br />

                <h3>Your Content in Our Service</h3>
                <br />
                <p>
                  Our service allows you to upload, download and store content,
                  including but not limited to, information, text, graphics, or
                  other material ("Content"). You retain ownership of any
                  intellectual property rights that you hold in that Content.
                  When upload, store, send or receive Content to or through our
                  Service, you give Virtus and its service partners a worldwide
                  license to host, store, upload and download this Content and
                  only for the purpose of providing Service strictly to you and
                  your use of the Content. We reserve our right at all times,
                  but are not obligated, to remove or refuse to distribute any
                  Content through the Service including your Content.
                </p>
                <br />

                <h3>Software in Our Service</h3>
                <br />
                <p>
                  When the Service requires or includes downloadable software,
                  this software may update automatically on your device once a
                  new version or features become available to you. Some
                  platforms may let you adjust your automatic update settings.
                  Virtus gives you a personal, worldwide license to use the
                  software provided by Virtus as part of the Service. You may
                  not copy, modify, distribute, sell or lease any part of our
                  Service or included software, nor may you reverse engineer or
                  attempt to extract the source code of the software, unless
                  local laws prohibit those restrictions or you have our written
                  permission.
                </p>
                <br />

                <h3>Modifying and Terminating Our Service</h3>
                <br />
                <p>
                  Virtus may add or remove functionallities or features in the
                  normal course of improving, changing, and/or updating the
                  Service. You can choose to stop using our Service at will. We
                  may also stop providing Service to you, or add or create new
                  limitations to our Service at any time as detailed in the
                  Terms.
                </p>
                <br />

                <h3>Using Our Service</h3>
                <br />
                <p>
                  You must follow any policies made available to you within the
                  Service. You may only use our Service as permitted by law.
                  Virtus may investigate and/or suspend or terminate our Service
                  to you at any time if we find your use of our Service violates
                  the Terms and/or any policies. Using our Service does not
                  grand you ownership of any intellectual property rights in our
                  Service or the content you may have access to. You may not use
                  any copyrighted content in our Service unless permitted by
                  law. The Terms do not grand you the right to use any branding
                  or logos used in our Service. Our Service may display some
                  logos, trademarks, or branding materials that is not the
                  property of Virtus. These types of content are the sole
                  responsebility of the entity that makes it available. You must
                  not abuse and/or misuse our Service, including not limited to,
                  doing the following things:
                  <ul>
                    <li>
                      Using the Service for any unlawful puropses or activities;
                    </li>
                    <li>
                      Uploading any content to the Service in violation of any
                      applicable law, including but not limited to, intellectual
                      property laws and publicity laws;
                    </li>
                    <li>Sending unsolicited promotions or advertisements;</li>
                    <li>
                      Accessing or tampering with the Service’s server systems;
                    </li>
                    <li>
                      Interfering with or disrupting the access of any user,
                      host, or network;
                    </li>
                    <li>
                      Abusing or submitting excessively frequent requests to the
                      Service via the API.
                    </li>
                  </ul>
                  Virtus, in its sole discretion, will determine abuse and/or
                  misuse of our Service.
                </p>
                <br />

                <h3>Premium Subscription Service and Payment</h3>
                <br />
                <p>
                  You may choose our free Service or paid subscription Service
                  which automatically renews at the end of your billing period
                  (“Premium”) depending on your need. We do not guarantee when,
                  if ever, Premium features will be available in the free
                  Service. You can upgrade from free Service to Premium at any
                  time.
                  <br />
                  <br />
                  When a free trial of Premium is offered, you will have access
                  to all Premium features. After the free trial period, if you
                  choose not to upgrade to Premium, features available to you
                  will be limited to free Service. You will be charged with the
                  amount shown on Pricing before you can access Premium. All
                  prices shown on Pricing are inclusive of any applicable sales
                  taxes, levies, value-added taxes, or duties imposed by taxing
                  authorities, and you are responsible for the
                  forwarding/remittance of all such taxes, levies, or duties
                  (except for E.U. Customers, where the Euro pricing includes
                  mandatory E.U. VAT charges).
                  <br />
                  <br />
                  BY PURCHASING PREMIUM YOU EXPRESSLY UNDERSTAND AND AGREE TO
                  OUR REFUND POLICY:
                  <br />
                  <br />
                  FOR ANY CUSTOMER WHO PURCHASED PREMIUM IN GOOGLE INC.’S PLAY
                  STORE (“PLAY STORE”). PLEASE REFER TO GOOGLE INC.’S SUPPORT
                  PAGE FOR DETAILED INSTRUCTIONS:{" "}
                  <a
                    href="https://support.google.com/googleplay/answer/2479637"
                    target="_blank"
                  >
                    Get a refund on Google
                  </a>
                  .
                  <br />
                  <br />
                  FOR ANY CUSTOMER WHO PURCHASED PREMIUM IN APPLE INC.’s APP
                  STORE (“APP STORE”), PLEASE CONTACT APPLE INC.’s SUPPORT TEAM:{" "}
                  <a href="https://reportaproblem.apple.com/" target="_blank">
                    Report a Problem
                  </a>
                  . APP STORE DOES NOT ALLOW DEVELOPERS TO ISSUE REFUND FOR APP
                  STORE PURCHASES MADE BY CUSTOMERS.
                </p>
                <br />

                <h3>Limited Liability for Our Service</h3>
                <br />
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICE IS
                  AVAILABLE “AS IS”. YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
                  <br />
                  <br />
                  WHEN PERMITTED BY LAW, Virtus AND ITS SERVICE PARTNERS,
                  LICENSORS, EMPLOYEES, AGENTS WILL NOT BE RESPONSIBLE FOR ANY
                  LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT,
                  SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES UNDER
                  ANY CIRCUMSTANCES.
                  <br />
                  <br />
                  YOUR USE AND/OR PURCHASE OF SERVICE ARE ALL AT YOUR SOLE RISK.
                  THE SERVICE IS PROVIDED ON AN “AS IS” BASIS.
                  <br />
                  <br />
                  Virtus DOES NOT WARRANT THAT:
                  <ul>
                    <li>
                      THE SERVICE WILL MEET ALL OF YOUR REQUIREMENTS AT ALL
                      TIMES;
                    </li>
                    <li>
                      THE SERVICE WILL BE ERROR-FREE AND ALL ERRORS IN THE
                      SERVICE WILL BE CORRECTED;
                    </li>
                    <li>THE SERVICE WILL BE UNINTERRUPTED.</li>
                  </ul>
                  ALL CONTENT DOWNLOADED, UPLOADED AND/OR OTHERWISE OBTAINED
                  THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION
                  AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                  COMPUTER EQUIPMENT OR DEVICES, INCLUDING BUT NOT LIMITED TO,
                  LAPTOP COMPUTERS, DESKTOP COMPUTER, TABLETS, SMARTPHONES AND
                  SMARTWATCHES, OR ANY DATA LOSS RESULTING FROM DOWNLOAD OR USE
                  OF ANY SUCH ABOVE MENTIONED MATERIAL.
                </p>

                <p>
                  We disclaim all liability related to your use of our service,
                  or your inability to use our service. To the extent liability
                  cannot be excluded or disclaimed, liability is limited to
                  $1.00 (USD).
                </p>
                <br />

                <h3>Information gathering and usage</h3>
                <br />
                <p>
                  When registering for Virtus we ask for information such as
                  your name and email address. Your information is only used
                  internally and won’t be shared with others.
                </p>

                <Link to="/">Go Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PrivacyPolicyPage
